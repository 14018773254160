import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocalization } from 'gatsby-theme-i18n'

const Seo = ({ content }) => {
  const { locale } = useLocalization()
  let companyName = {
    en: 'Leege',
    zh: '力景',
  }
  companyName = companyName[locale]
  const { title, titleFull, description, keywords } = content
  let setTitle = titleFull ? titleFull : `${companyName} | ${title}`
  return (
    <Helmet>
      <title>{setTitle}</title>
      {description && <meta name='description' content={description} />}
      {keywords && <meta name='keywords' content={keywords} />}
    </Helmet>
  )
}

export default Seo
