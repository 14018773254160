import React from 'react'
import Image from 'components/Image'
import LineHeadline from 'components/LineHeadline'
import Inview from 'components/Inview'

const BlockTextImage = ({
  content,
  isMobileImageFirst,
  isLarger,
  isLightenImage,
  isOpacity75,
}) => {
  const { section, highlight, body, image, caption } = content

  return (
    <section className='block-text-image'>
      <div className='container'>
        {section && <LineHeadline text={section} />}
        <div
          className={`main 
            ${isMobileImageFirst ? 'mobile-image-first' : ''}
            ${isLarger ? 'larger' : ''}
          `}
        >
          <Inview className='text fade-in up'>
            {highlight && <h4>{highlight}</h4>}
            <div className='body' dangerouslySetInnerHTML={{ __html: body }} />
          </Inview>
          <Inview
            className={`image fade-in up
          ${isLightenImage ? 'lighten' : ''}
          ${isOpacity75 ? 'opacity-75' : ''}
          `}
          >
            <Image filename={image} />
            {caption && <h5 className='caption'>{caption}</h5>}
          </Inview>
        </div>
      </div>
    </section>
  )
}

export default BlockTextImage
