import React from 'react'
import Image from 'components/Image'

const Social = () => {
  return (
    <div className='social'>
      <div className='items'>
        <div className='item'>
          <Image className='icon' filename='icon-wechat-white.png' />
          <Image className='qr' filename='qr-wechat.jpg' />
        </div>
        {/* <div className='item'>
          <Image className='icon' filename='icon-weibo-white.png' />
          <Image className='qr' filename='qr-wechat.jpg' />
        </div> */}
      </div>
    </div>
  )
}

export default Social
