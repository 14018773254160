import React from 'react'
import Image from 'components/Image'
import Inview from 'components/Inview'
import ParallaxWrapBanner from 'components/ParallaxWrapBanner'

const BlockBanner = ({ content }) => {
  const { image, imageMobile, title } = content
  return (
    <section className='block-banner block'>
      <Inview className='inner'>
        <ParallaxWrapBanner>
          <div className='image image-desktop fade-in up'>
            <Image filename={image} />
          </div>
          <div className='image image-mobile fade-in up'>
            <Image filename={imageMobile} />
          </div>
        </ParallaxWrapBanner>
        <div className='text fade-in stagger-500'>
          <div className='wrap'>
            <h1>{title}</h1>
          </div>
        </div>
      </Inview>
    </section>
  )
}

export default BlockBanner
