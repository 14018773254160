import React from 'react'
import Image from 'components/Image'
import LineHeadline from 'components/LineHeadline'
import Inview from 'components/Inview'

const BlockTextImageTable = ({ content }) => {
  const { section, items } = content
  return (
    <section className='block-text-image-table'>
      <div className='container'>
        <LineHeadline text={section} />
        <div className='main'>
          {items.map((item, i) => (
            <Item key={i} content={item} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default BlockTextImageTable

const Item = ({ content }) => {
  const { pretitle, title, body, image } = content
  return (
    <Inview className='row'>
      <div className='border fade-in up'>
        <h4 className='pretitle'>{pretitle}</h4>
        <div className='line' />
      </div>
      <div className='inner'>
        <div className='text fade-in up'>
          {title && <h3>{title}</h3>}
          <div className='body' dangerouslySetInnerHTML={{ __html: body }} />
        </div>
        <div className='image fade-in stagger-500'>
          <Image filename={image} />
        </div>
      </div>
    </Inview>
  )
}
