export const scrollToContact = () => {
  const block = document.querySelector('.BlockContact')
  const nav = document.querySelector('.nav')
  const blockY = block.offsetTop + nav.offsetHeight
  window.scrollTo({
    top: blockY,
    behavior: 'smooth',
  })
}

export const makeYellow = (filename) => {
  const arr = filename.split('.')
  const newFilename = `${arr[0]}_yellow.${arr[1]}`
  return newFilename
}
