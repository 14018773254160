import React from 'react'
import Image from 'components/Image'
import Inview from 'components/Inview'

const BlockBannerMidIllustrated = ({ content }) => {
  const { image, title, subtitle } = content
  return (
    <section className='block-banner-mid-illustrated'>
      <Inview>
        <div className='image fade-in up'>
          <Image filename={image} />
        </div>
        <div className='container'>
          <div className='text fade-in stagger-500'>
            <div className='wrap'>
              <h1>{title}</h1>
              <h2>{subtitle}</h2>
            </div>
          </div>
        </div>
      </Inview>
    </section>
  )
}

export default BlockBannerMidIllustrated
