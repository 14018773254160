import React from 'react'
import Image from 'components/Image'
import ButtonLink from 'components/ButtonLink'
import Social from 'components/Social'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'

const Footer = ({ content }) => {
  const { locale } = useLocalization()
  const { image, imageMobile, title, subtitle, button, button2 } = content

  let copyright = {
    en: `Copyright ©2021 LEEGE (BEIJING) TECHNOLOGY CO., LTD. All rights reserved. Credit: <a href="https://lantern.digital" target="_blank">web design</a>. <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备13044688号-3</a>`,
    zh: `版权所属©2021 力景（北京）系统技术有限公司保留所有权利 <a href="https://www.lanterndigital.com.cn" target="_blank">网站设计</a> <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备13044688号-3</a>`,
  }
  copyright = copyright[locale]

  return (
    <footer className='footer'>
      <div className='bg-gradient-darken' />
      <Inview>
        <div className={`inner ${!button ? 'no-buttons' : ''}`}>
          <div className='image image-desktop fade-in up'>
            <Image filename={image} />
          </div>
          <div className='image image-mobile fade-in up'>
            <Image filename={imageMobile} />
          </div>
          <div className='text'>
            <div className='wrap'>
              <div className='upper fade-in stagger-500'>
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
                {subtitle && <h2>{subtitle}</h2>}
                {button && (
                  <div className='buttons fade-in stagger-1000'>
                    {button && <ButtonLink content={button} />}
                    {button2 && <ButtonLink content={button2} />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='lower fade-in stagger-1000'>
          <div className='left'>
            <div className='logo'>
              <LinkWrap to='/'>
                <Image filename='logo-no-bg.png' />
              </LinkWrap>
            </div>
            <p dangerouslySetInnerHTML={{ __html: copyright }} />
          </div>
          <div className='right'>
            <Social />
          </div>
        </div>
      </Inview>
    </footer>
  )
}

export default Footer
