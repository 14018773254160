import React from 'react'
import Inview from 'components/Inview'

const LineHeadline = ({ text }) => {
  return (
    <Inview className='component-line-headline fade-in up'>
      <div className='line' />
      <h3>{text}</h3>
    </Inview>
  )
}

export default LineHeadline
