import React, { useState } from 'react'
import Image from 'components/Image'
import { makeYellow } from 'js/utils'
import Inview from 'components/Inview'

const BlockAccordion = ({ content }) => {
  const { rows } = content
  return (
    <section className='block-accordion'>
      <Inview className='container'>
        {rows.map((row, i) => (
          <Row key={i} i={i} content={row} />
        ))}
      </Inview>
    </section>
  )
}

export default BlockAccordion

const Row = ({ content, i }) => {
  const { icon, title, highlight, description, points } = content
  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={`row fade-in up stagger-${i * 200}`}>
      <div className={`top ${isOpen ? 'open' : ''}`} onClick={toggleIsOpen}>
        <div className='icon'>
          <Image className='default' filename={icon} />
          <Image className='open' filename={makeYellow(icon)} />
        </div>
        <h3>{title}</h3>
        <div className='expand' />
      </div>
      <div className={`main ${isOpen ? 'open' : ''}`}>
        <div className='inner'>
          <div className='highlight'>
            <h4>{highlight}</h4>
          </div>
          <div className='text'>
            <div className='description'>{description}</div>
            <div
              className='points body'
              dangerouslySetInnerHTML={{ __html: points }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
