import React from 'react'
import LinkWrap from 'components/LinkWrap'

const ButtonLink = ({ content }) => {
  const { to, text, isExternal } = content
  if (isExternal) {
    return (
      <a href={to} target='_blank' className='component-button-link'>
        <Inner text={text} />
      </a>
    )
  } else {
    return (
      <LinkWrap to={to} className='component-button-link'>
        <Inner text={text} />
      </LinkWrap>
    )
  }
}

export default ButtonLink

const Inner = ({ text }) => {
  return (
    <>
      <span className='text'>{text}</span>
      <span className='bg'>
        <span className='inner-text'>{text}</span>
      </span>
    </>
  )
}
