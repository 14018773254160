import React from 'react'
import Footer from 'components/Footer'
import Seo from 'components/Seo'
import BlockBanner from 'components/blocks/BlockBanner'
import BlockTextImage from 'components/blocks/BlockTextImage'
import BlockTextImageTable from 'components/blocks/BlockTextImageTable'
import BlockBannerMidIllustrated from 'components/blocks/BlockBannerMidIllustrated'
import BlockAccordion from 'components/blocks/BlockAccordion'
import { content, filterContentByLocale } from 'content/content.js'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'

const BusinessPage = () => {
  const { locale } = useLocalization()
  const localeContent = filterContentByLocale(content, locale)
  const { business, footer } = localeContent
  const { banner, textImage, textImageTable, bannerMidIllustrated, accordion } =
    business

  let seoContent = {
    title: {
      en: `Business`,
      zh: `业务模式`,
    },
  }
  seoContent = filterContentByLocale(seoContent, locale)

  return (
    <div>
      <div className='page'>
        <Seo content={seoContent} />
        <div className='bg-gradient'>
          <BlockBanner content={banner} />
          <BlockTextImage
            content={textImage}
            isLarger={true}
            isLightenImage={true}
          />
          <BlockTextImageTable content={textImageTable} />
          <BlockBannerMidIllustrated content={bannerMidIllustrated} />
          <BlockAccordion content={accordion} />
          <Footer content={footer.default} />
        </div>
      </div>
    </div>
  )
}

export default BusinessPage
